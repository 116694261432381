





























import { Component, Vue } from 'vue-property-decorator';
import { ReglementInformations, InfoRibCotisation, CotisationsTable } from '@/components';
import { CotisationElement, ReglementCotisation, CotisationRib } from '@/models';
import { contextEmployeur, cotisations, demo } from '@/store';
@Component({
  components: { ReglementInformations, InfoRibCotisation, CotisationsTable }
})
export default class Cotisations extends Vue {
  private reglementCotisation: ReglementCotisation | null = null;

  private reglementRibActif: CotisationRib | null = null;

  private cotisations: CotisationElement[] | null = null;

  private selectedYear = new Date().getFullYear();

  async mounted() {
    // GET COTISATIONS
    if (
      !this.contextEmployeur?.numeroPersonneMorale ||
      !this.contextEmployeur?.contrat?.numeroContrat ||
      this.contextEmployeur.contrat.versionContrat === null
    )
      return;
    await Promise.all([
      cotisations.initEcheancierCotisations({
        numContrat: this.contextEmployeur.contrat.numeroContrat,
        version: this.contextEmployeur.contrat.versionContrat,
        annee: this.selectedYear,
        isDemo: this.getDemo
      }),
      cotisations.initInfoRib({
        isDemo: this.getDemo,
        npm: this.contextEmployeur.numeroPersonneMorale
      }),
      cotisations.initReglementCotisation({
        numContrat: this.contextEmployeur.contrat.numeroContrat,
        version: this.contextEmployeur.contrat.versionContrat,
        isDemo: this.getDemo
      })
    ]);

    this.cotisations = cotisations.getCotisations;
    this.reglementRibActif = cotisations.getRibActif;
    this.reglementCotisation = cotisations.getReglementCotisation;
  }

  private async updateData(value: number) {
    if (!this.contextEmployeur?.contrat?.numeroContrat || this.contextEmployeur.contrat.versionContrat === null) return;
    this.selectedYear += value;
    await cotisations.initEcheancierCotisations({
      numContrat: this.contextEmployeur.contrat.numeroContrat,
      annee: this.selectedYear,
      version: this.contextEmployeur.contrat.versionContrat,
      isDemo: this.getDemo
    });
    this.cotisations = cotisations.getCotisations;
  }

  get contextEmployeur() {
    return contextEmployeur.getEmployeur;
  }

  get getDemo() {
    return demo.demoState;
  }

  get dateEffetContrat() {
    return this.contextEmployeur?.dateEffetContrat;
  }
}
